import React, { useState } from "react";
import { BACKEND_URL } from "../config";
import VideoCall from "./VideoCall";
import "./VideoCall.css";

function HostForm() {
  const [roomName, setRoomName] = useState("");
  const [cameras, setCameras] = useState([]);
  const [selectedCamera, setSelectedCamera] = useState("");
  const [sessionCode, setSessionCode] = useState("");
  const [sessionId, setSessionId] = useState("");
  const [error, setError] = useState("");
  const [sessionCreated, setSessionCreated] = useState(false);
  const [localStream, setLocalStream] = useState(null);

  // Helper: Force a new permission request.
  const forcePermissionRequest = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
      stream.getTracks().forEach(track => track.stop());
    } catch (err) {
      throw err;
    }
  };

  // Scan for devices by first requesting permissions.
  const scanForDevices = async () => {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      try {
        // Force the permission dialog.
        await forcePermissionRequest();

        const devices = await navigator.mediaDevices.enumerateDevices();
        const videoDevices = devices.filter(
          (device) => device.kind === "videoinput"
        );
        setCameras(videoDevices);
        if (videoDevices.length > 0) {
          setSelectedCamera(videoDevices[0].deviceId);
        }
        console.log("Host - Devices scanned:", videoDevices);
      } catch (err) {
        console.error("Error scanning devices:", err);
        setError("Unable to scan devices. Please grant camera and mic permissions.");
      }
    } else {
      console.error("Media Devices API not supported in this environment.");
      setError("Media Devices API not supported.");
    }
  };

  const handleCreateSession = async () => {
    setError("");
    console.log("Creating session for room:", roomName, "with camera:", selectedCamera);
    try {
      // Request permission before creating session.
      await forcePermissionRequest();

      const res = await fetch(`${BACKEND_URL}/api/sessions`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          roomName,
          hostCameraDeviceId: selectedCamera,
        }),
      });
      const data = await res.json();
      if (data.success) {
        console.log("Session created successfully:", data);
        setSessionCode(data.code);
        setSessionId(data.sessionId);
        setSessionCreated(true);
        // Request permission again to obtain the stream.
        const stream = await navigator.mediaDevices.getUserMedia({
          video: { deviceId: { exact: selectedCamera } },
          audio: true,
        });
        setLocalStream(stream);
        console.log("Obtained local stream for host");
      } else {
        console.error("Error creating session:", data.error);
        setError(data.error || "Unknown error creating session");
      }
    } catch (err) {
      console.error("Error creating session:", err);
      setError(err.message);
    }
  };

  return (
    <div className="host-form">
      {!sessionCreated ? (
        <>
          <div className="input-group">
            <label className="input-label">Room Name: </label>
            <input
              type="text"
              className="input-field"
              placeholder="Enter Session Name"
              value={roomName}
              onChange={(e) => setRoomName(e.target.value)}
            /> 
          </div>
          <div className="input-group">
            <label className="input-label">Select Camera: </label>
            <select
              className="camera-select"
              value={selectedCamera}
              onChange={(e) => setSelectedCamera(e.target.value)}
            >
              {cameras.map((cam, index) => (
                <option key={cam.deviceId} value={cam.deviceId}>
                  {cam.label || `Camera ${index + 1}`}
                </option>
              ))}
            </select>
          </div>
          <button className="btn-secondary" onClick={scanForDevices}>
            Scan for Devices
          </button>
          <button className="btn-primary" onClick={handleCreateSession}>
            Start Session
          </button>
          {error && <p className="error-message">{error}</p>}
        </>
      ) : (
        <VideoCall
          code={sessionCode}
          role="host"
          cameraDeviceId={selectedCamera}
          roomName={roomName}
          initialLocalStream={localStream}
        />
      )}
    </div>
  );
}

export default HostForm;
