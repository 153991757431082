import React from "react";
import { BrowserRouter as Router, Routes, Route, useNavigate } from "react-router-dom";
import HostForm from "./components/HostForm";
import ViewerForm from "./components/ViewerForm";
import "./components/VideoCall.css";
import Divider from '@mui/material/Divider';

function App() {
  // Function to request media permissions
  const requestMediaPermissions = () => {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices
        .getUserMedia({ video: true, audio: true })
        .then((stream) => {
          // Stop the tracks immediately after opening the permission dialog.
          stream.getTracks().forEach((track) => track.stop());
        })
        .catch((err) => {
          console.error("Permission request error:", err);
        });
    }
  };

  const HomePage = () => {
    const navigate = useNavigate();

    return (
      <div className="home-container">
        <button
          className="home-button"
          onClick={() => {
            requestMediaPermissions();
            navigate("/host");
          }}
        >
          Host Tele-Proctoring Session
        </button>
        <button
          className="home-button"
          onClick={() => {
            requestMediaPermissions();
            navigate("/viewer");
          }}
        >
          Join Session
        </button>
      </div>
    );
  };

  return (
    <Router>
      <div className="app-container">
        <h2 className="app-title">SSi Guru</h2>
        <Divider sx={{ borderBottom: '2px solid #bb86fc', width: '100%', marginBottom: '10px'}} />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/host" element={<HostForm />} />
          <Route path="/viewer" element={<ViewerForm />} />
          <Route path="/host/:sessionId" element={<HostForm />} />
          <Route path="/viewer/:sessionId" element={<ViewerForm />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
