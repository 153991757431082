import React, { useState } from "react";
import { BACKEND_URL } from "../config";
import VideoCall from "./VideoCall";
import "./VideoCall.css";

function ViewerForm() {
  const [code, setCode] = useState("");
  const [sessionData, setSessionData] = useState(null);
  const [error, setError] = useState("");
  const [cameras, setCameras] = useState([]);
  const [selectedCamera, setSelectedCamera] = useState("");
  const [readyToJoin, setReadyToJoin] = useState(false);
  const [localStream, setLocalStream] = useState(null);

  // Helper: Try to force a new permission request.
  const forcePermissionRequest = async () => {
    try {
      // Request media stream.
      const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
      // Immediately stop all tracks so that we only trigger the permission dialog.
      stream.getTracks().forEach(track => track.stop());
    } catch (err) {
      throw err;
    }
  };

  // Scan for devices by requesting permissions every time.
  const scanForDevices = async () => {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      try {
        // Force the browser to request permission
        await forcePermissionRequest();

        // Now enumerate devices.
        const devices = await navigator.mediaDevices.enumerateDevices();
        const videoDevices = devices.filter((device) => device.kind === "videoinput");
        setCameras(videoDevices);
        if (videoDevices.length > 0) {
          setSelectedCamera(videoDevices[0].deviceId);
        }
        console.log("Devices scanned:", videoDevices);
      } catch (err) {
        console.error("Error scanning devices:", err);
        setError("Unable to scan devices. Please grant camera and mic permissions.");
      }
    } else {
      console.error("Media Devices API not supported in this environment.");
      setError("Media Devices API not supported.");
    }
  };

  const handleCheckSession = async () => {
    setError("");
    setSessionData(null);
    console.log("Checking session with code:", code);
    try {
      const res = await fetch(`${BACKEND_URL}/api/sessions/${code}`);
      const data = await res.json();
      if (!data.success) {
        console.error("Session check failed:", data.message);
        setError(data.message || "Session not found or inactive");
        return;
      }
      console.log("Session found:", data.sessionData);
      setSessionData(data.sessionData);
    } catch (err) {
      console.error("Error checking session:", err);
      setError(err.message);
    }
  };

  const handleJoinSession = async () => {
    if (!sessionData) {
      console.error("No session data available to join");
      setError("No session data to join");
      return;
    }
    try {
      // Request permissions each time the user clicks Join Session.
      const stream = await navigator.mediaDevices.getUserMedia({
        video: { deviceId: { exact: selectedCamera } },
        audio: true,
      });
      setLocalStream(stream);
      console.log("Obtained local stream for viewer");
      setReadyToJoin(true);
    } catch (err) {
      console.error("Error obtaining local stream:", err);
      setError("Could not access camera/mic. Please check permissions.");
    }
  };

  return (
    <div className="viewer-form">
      {!readyToJoin ? (
        <>
          <div className="input-group">
            <label className="input-label">Session Access Code: </label>
            <input
              type="text"
              className="input-field"
              placeholder="Enter Session Code"
              value={code}
              onChange={(e) => setCode(e.target.value)}
            />
            <button className="btn-primary" onClick={handleCheckSession}>
              Check Session
            </button>
          </div>

          {error && <p className="error-message">{error}</p>}

          {sessionData && (
            <div className="session-info">
              <h3 className="session-title">Session Found: {sessionData.roomName}</h3>
              <div className="input-group">
                <label className="input-label">Select Camera: </label>
                <select
                  className="camera-select"
                  value={selectedCamera}
                  onChange={(e) => setSelectedCamera(e.target.value)}
                >
                  {cameras.map((cam, index) => (
                    <option key={cam.deviceId} value={cam.deviceId}>
                      {cam.label || `Camera ${index + 1}`}
                    </option>
                  ))}
                </select>
              </div>
              <button className="btn-secondary" onClick={scanForDevices}>
                Scan for Devices
              </button>
              <button className="btn-primary" onClick={handleJoinSession}>
                Join Session
              </button>
            </div>
          )}
        </>
      ) : (
        <VideoCall
          code={sessionData.code}
          role="viewer"
          cameraDeviceId={selectedCamera}
          roomName={sessionData.roomName}
          initialLocalStream={localStream}
        />
      )}
    </div>
  );
}

export default ViewerForm;
