import React, { useEffect, useState, useRef } from "react";
import io from "socket.io-client";
import { BACKEND_URL } from "../config";
import "./VideoCall.css";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

function VideoCall({
  code,
  role,
  cameraDeviceId,
  roomName,
  initialLocalStream,
}) {
  const [socket, setSocket] = useState(null);
  const [sessionId, setSessionId] = useState("");
  const [userId, setUserId] = useState("");
  const [localStream, setLocalStream] = useState(initialLocalStream || null);
  const [peerConnections, setPeerConnections] = useState({});
  const [remoteStreams, setRemoteStreams] = useState({});
  const [error, setError] = useState("");
  const [joined, setJoined] = useState(false);
  const [availableCameras, setAvailableCameras] = useState([]);
  const [currentCamera, setCurrentCamera] = useState(cameraDeviceId);
  const [copySuccess, setCopySuccess] = useState(false);

  const localVideoRef = useRef(null);

  useEffect(() => {
    const s = io(BACKEND_URL);
    setSocket(s);
    console.log("Socket connection initialized");
    return () => {
      s.disconnect();
      console.log("Socket disconnected");
    };
  }, []);

  useEffect(() => {
    if (!localStream) {
      navigator.mediaDevices
        .getUserMedia({
          video: currentCamera ? { deviceId: { exact: currentCamera } } : true,
          audio: true,
        })
        .then((stream) => {
          setLocalStream(stream);
          console.log("Obtained local stream");
          if (localVideoRef.current) {
            localVideoRef.current.srcObject = stream;
          }
        })
        .catch((err) => {
          console.error("Error accessing media devices:", err);
          setError("Could not access camera/mic.");
        });
    } else if (localVideoRef.current) {
      localVideoRef.current.srcObject = localStream;
    }
  }, [localStream, currentCamera]);

  // Updated scanDevices to always ask permission first.
  const scanDevices = async () => {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      try {
        await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
        const devices = await navigator.mediaDevices.enumerateDevices();
        const videoDevices = devices.filter(
          (device) => device.kind === "videoinput"
        );
        setAvailableCameras(videoDevices);
        console.log("Scanned devices:", videoDevices);
      } catch (err) {
        console.error("Error scanning devices:", err);
      }
    } else {
      console.error("Media Devices API not supported in this environment.");
    }
  };

  useEffect(() => {
    scanDevices();
  }, []);

  const handleCameraChange = (e) => {
    const newCameraId = e.target.value;
    console.log("Switching camera to:", newCameraId);
    setCurrentCamera(newCameraId);
    if (localStream) {
      localStream.getTracks().forEach((track) => track.stop());
    }
    navigator.mediaDevices
      .getUserMedia({
        video: { deviceId: { exact: newCameraId } },
        audio: true,
      })
      .then((newStream) => {
        setLocalStream(newStream);
        if (localVideoRef.current) {
          localVideoRef.current.srcObject = newStream;
        }
        Object.values(peerConnections).forEach((pc) => {
          const senders = pc
            .getSenders()
            .filter((sender) => sender.track && sender.track.kind === "video");
          const newVideoTrack = newStream.getVideoTracks()[0];
          senders.forEach((sender) => {
            sender.replaceTrack(newVideoTrack);
          });
        });
        console.log("Camera switched successfully");
      })
      .catch((err) => {
        console.error("Error switching camera:", err);
        setError("Failed to switch camera.");
      });
  };

  useEffect(() => {
    if (sessionId) {
      const newUrl = `/${role}/${sessionId}`;
      window.history.replaceState(null, "", newUrl);
      console.log("Updated URL to:", newUrl);
    }
  }, [sessionId, role]);

  useEffect(() => {
    if (!socket || !code || !role || !localStream) return;
    console.log("Emitting join-room with", {
      code,
      role,
      cameraDeviceId: currentCamera,
    });
    socket.emit("join-room", { code, role, cameraDeviceId: currentCamera });
    setJoined(true);
  }, [socket, code, role, localStream, currentCamera]);

  // (Socket event handlers remain unchanged.)
  useEffect(() => {
    if (!socket) return;

    const handleNoRoom = () => {
      setError("No such session or it's inactive.");
      console.log("Received no-room event");
    };

    const handleRoomJoined = ({ room, sessionId, role, userId }) => {
      console.log("Room joined:", room, sessionId, role, userId);
      setSessionId(sessionId);
      setUserId(userId);
    };

    const handleRoomFull = (data) => {
      console.log("Received room-full:", data);
      setError(data.message);
    };

    const handleHostAlreadyExists = (data) => {
      console.log("Received host-already-exists:", data);
      setError(data.message);
    };

    socket.on("no-room", handleNoRoom);
    socket.on("room-joined", handleRoomJoined);
    socket.on("room-full", handleRoomFull);
    socket.on("host-already-exists", handleHostAlreadyExists);

    if (role === "host") {
      socket.on("viewer-joined", ({ viewerId, cameraDeviceId }) => {
        console.log("Viewer joined event received for viewerId:", viewerId);
        if (peerConnections[viewerId]) {
          console.log("Peer connection already exists for", viewerId);
          return;
        }
        const pc = new RTCPeerConnection({
          iceServers: [
            { urls: "stun:stun.l.google.com:19302" },
            {
              urls: "turn:ssiguru.com:3478",
              username: "webrtcuser",
              credential: "strongpassword123"
            }
          ]
        });        
        localStream.getTracks().forEach((track) => pc.addTrack(track, localStream));
        pc.onicecandidate = (event) => {
          if (event.candidate) {
            socket.emit("ice-candidate", {
              target: viewerId,
              candidate: event.candidate,
            });
            console.log("Sent ICE candidate to", viewerId, event.candidate);
          }
        };
        pc.ontrack = (event) => {
          console.log("Received remote track from viewer", viewerId);
          setRemoteStreams((prev) => ({
            ...prev,
            [viewerId]: event.streams[0],
          }));
        };
        pc.createOffer()
          .then((offer) => {
            console.log("Created offer for viewer", viewerId, offer);
            return pc.setLocalDescription(offer);
          })
          .then(() => {
            console.log("Sending offer to viewer", viewerId);
            socket.emit("offer", {
              target: viewerId,
              sdp: pc.localDescription,
            });
          })
          .catch((err) => {
            console.error("Error creating offer for viewer", viewerId, err);
          });
        setPeerConnections((prev) => ({ ...prev, [viewerId]: pc }));
      });

      socket.on("viewer-disconnected", ({ viewerId }) => {
        console.log("Viewer disconnected:", viewerId);
        if (peerConnections[viewerId]) {
          peerConnections[viewerId].close();
          const updatedPC = { ...peerConnections };
          delete updatedPC[viewerId];
          setPeerConnections(updatedPC);
        }
        setRemoteStreams((prev) => {
          const updated = { ...prev };
          delete updated[viewerId];
          return updated;
        });
      });

      socket.on("answer", ({ sdp, caller }) => {
        console.log("Received answer from", caller);
        const pc = peerConnections[caller];
        if (pc) {
          pc.setRemoteDescription(new RTCSessionDescription(sdp))
            .then(() => {
              console.log("Set remote description for", caller);
            })
            .catch((err) => {
              console.error("Error setting remote description for", caller, err);
            });
        }
      });
    }

    if (role === "viewer") {
      socket.on("offer", async ({ sdp, caller }) => {
        console.log("Received offer from", caller);
        if (peerConnections[caller]) {
          console.log("Peer connection already exists for", caller);
          return;
        }
        const pc = new RTCPeerConnection({
          iceServers: [
            { urls: "stun:stun.l.google.com:19302" },
            {
              urls: "turn:ssiguru.com:3478",
              username: "webrtcuser",
              credential: "strongpassword123"
            }
          ]
        });        
        localStream.getTracks().forEach((track) => pc.addTrack(track, localStream));
        pc.onicecandidate = (event) => {
          if (event.candidate) {
            socket.emit("ice-candidate", {
              target: caller,
              candidate: event.candidate,
            });
            console.log("Sent ICE candidate to", caller, event.candidate);
          }
        };
        pc.ontrack = (event) => {
          console.log("Received remote track from", caller);
          setRemoteStreams((prev) => ({ ...prev, [caller]: event.streams[0] }));
        };
        try {
          await pc.setRemoteDescription(new RTCSessionDescription(sdp));
          console.log("Set remote description from offer by", caller);
          const answer = await pc.createAnswer();
          console.log("Created answer for", caller, answer);
          await pc.setLocalDescription(answer);
          socket.emit("answer", {
            target: caller,
            sdp: pc.localDescription,
          });
          console.log("Sent answer to", caller);
        } catch (err) {
          console.error("Error handling offer from", caller, err);
        }
        setPeerConnections((prev) => ({ ...prev, [caller]: pc }));
      });

      socket.on("answer", async ({ sdp, caller }) => {
        console.log("Received answer from", caller);
        const pc = peerConnections[caller];
        if (pc) {
          try {
            await pc.setRemoteDescription(new RTCSessionDescription(sdp));
            console.log("Set remote description for", caller);
          } catch (err) {
            console.error("Error setting remote description for", caller, err);
          }
        }
      });
    }

    socket.on("ice-candidate", ({ candidate, caller }) => {
      console.log("Received ICE candidate from", caller, candidate);
      const pc = peerConnections[caller];
      if (pc && candidate) {
        pc.addIceCandidate(new RTCIceCandidate(candidate)).catch((e) => {
          console.error("Error adding ICE candidate for", caller, e);
        });
      }
    });

    return () => {
      socket.off("no-room", handleNoRoom);
      socket.off("room-joined", handleRoomJoined);
      socket.off("room-full", handleRoomFull);
      socket.off("host-already-exists", handleHostAlreadyExists);
      if (role === "host") {
        socket.off("viewer-joined");
        socket.off("viewer-disconnected");
        socket.off("answer");
      }
      if (role === "viewer") {
        socket.off("offer");
        socket.off("answer");
      }
      socket.off("ice-candidate");
    };
  }, [socket, localStream, peerConnections, role]);

  const handleEndSession = () => {
    console.log("Ending/leaving session");
    if (socket && code) {
      socket.emit("leave-room", { code });
      console.log("Emitted leave-room event with code:", code);
    }
    Object.values(peerConnections).forEach((pc) => {
      pc.close();
    });
    setPeerConnections({});
    setRemoteStreams({});
    if (localStream) {
      localStream.getTracks().forEach((track) => track.stop());
    }
    if (socket) {
      socket.disconnect();
    }
    window.history.replaceState(null, "", "/");
    window.location.reload(true);
    console.log("Session ended/leaved. Navigated to home.");
  };

  const handleCopyCode = () => {
    navigator.clipboard
      .writeText(code)
      .then(() => {
        setCopySuccess(true);
        toast.success(`Code "${code}" copied successfully!`, {
          position: "top-center",
          theme: "colored",
          autoClose: 500,
        });
        setCopySuccess(false);
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };
  
  return (
    <div className="video-call">
      <ToastContainer />
      <div className="session-details">
        {/* <h3 className="session-header">{role === "host" ? "Host" : "Viewer"} Session</h3> */}
        <p>
          <span className="label">Session Code:</span>
          <span className="content">
            <strong>{code}</strong>
            <ContentCopyRoundedIcon
              onClick={handleCopyCode}
              className="icon"
              titleAccess="Copy Session Code"
            />
          </span>
        </p>
        <p>
          <span className="label">Room:</span>
          <span className="content">{roomName}</span>
        </p>
        <p>
          <span className="label">Session ID:</span>
          <span className="content">{sessionId}</span>
        </p>
        <p>
          <span className="label">User ID:</span>
          <span className="content">{userId}</span>
        </p>
        {/* {error && <p className="error-message">{error}</p>} */}
      </div>
      <div className="camera-settings">
        <label>Switch Camera: </label>
        <select
          className="camera-select"
          value={currentCamera}
          onChange={handleCameraChange}
        >
          {availableCameras.map((cam, index) => (
            <option key={cam.deviceId} value={cam.deviceId}>
              {cam.label || `Camera ${index + 1}`}
            </option>
          ))}
        </select>
        <button className="btn-secondary" onClick={scanDevices}>
          Scan for Devices
        </button>
      </div>

      <div className="video-container">
        <div className="video-box">
          <p>Host ID: {userId || "N/A"}</p>
          <video ref={localVideoRef} autoPlay muted className="video-stream" />
        </div>
        {Object.keys(remoteStreams).map((remoteId) => (
          <div key={remoteId} className="video-box">
            <p>User ID: {remoteId}</p>
            <video
              autoPlay
              className="video-stream"
              ref={(el) => {
                if (el && remoteStreams[remoteId]) {
                  el.srcObject = remoteStreams[remoteId];
                }
              }}
            />
          </div>
        ))}
      </div>

      <div className="button-container">
        {role === "host" ? (
          <button className="btn-danger" onClick={handleEndSession}>
            End Session
          </button>
        ) : (
          <button className="btn-danger" onClick={handleEndSession}>
            Leave Session
          </button>
        )}
      </div>
    </div>
  );
}

export default VideoCall;
